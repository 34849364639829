// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
import {getUnitDefaultRouteParams} from "@/router/prometheus/portal/units";
const pageClass = 'units-groups-page';

const defaultSetRoute = {
    unitId: (route, router, unitId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
            if(this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        let query = route.query
        delete query.groupsIds
        router.push({
            name: routeParams.routeName.info,
            params: {
                ...routeParams.params
            },
            query
        })
    },
    groupEdit: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
            if(this.$isDevelopment) console.warn('groupId', route);
            return false;
        }

        router.push({
            name: consts.routerNames.units_groups.edit,
            params: {
                groupId
            },
            query: {...route.query}
        })
    },
    unitInfo: (route, router, unitId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
            if(this.$isDevelopment) console.warn('unitId', route);
            return false;
        }
        let routeParams = getUnitDefaultRouteParams(unitId)
        router.push({
            name: routeParams.routeName.info,
            params: {
                ...routeParams.params
            },
            query: {...route.query}
        })
    },
    group: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
            if(this.$isDevelopment) console.warn('units_groups', route);
            return false;
        }

        let query = route.query
        delete query.groupsIds

        router.push({
            name: consts.routerNames.units_groups.main,
            params: {
                groupId
            },
            query
        })
    },
    groupMap: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
            if(this.$isDevelopment) console.warn('units_groups', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units_groups.map,
            params: {
                groupId
            },
            query: {...route.query}
        })
    },
    multipleGroups(route, router, groupsIds) {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
            if(this.$isDevelopment) console.warn('units_groups', route);
            return false;
        }
        router.push({
            name: consts.routerNames.units_groups.main,
            params: {
                groupId: null,
            },
            query: {
                ...route.query,
                groupsIds
            }
        })
    },
    unitsFilter: (route, router, filter) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
            if(this.$isDevelopment) console.warn('unitsFilter', route);
            return false
        }
        router.push({
            name: route.name,
            params: {
                ...route.params
            },
            query: {
                name: filter?.name || undefined,
                movement: filter?.movement || undefined,
                signal: filter?.signal || undefined,
            }
        })
    },
    md: (route, router, groupId) => {
        // if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.units_groups.main) {
        //     if(this.$isDevelopment) console.warn('md', route);
        //     return false
        // }
        router.push({
            name: consts.routerNames.md.fleet.main,
            params: {
                groupId
            },
            query: {
            }
        })
    },
}

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'units-groups/:groupId(\\d+)?',
        name: consts.routerNames.units_groups.main,
        meta: {
            list: 'UnitsList',
            mainRouteName: consts.routerNames.units_groups.main,
            pageClass,
            layers: {
                ...consts.defaultSettingsLayers,
                units: {visible: false, showInList: false, showLayer: false},
                units_groups: {visible: true, showInList: false, showLayer: true},
                geoitems: {visible: false, showInList: true, showLayer: true},
            },
            setRoute: defaultSetRoute,
        },
        props: {
          default: (route) => {
              return {
                  groupId: route?.params?.groupId
              }
          }
        },
        components: {
            default: () => import('@/views/prometheus/portal/UnitsGroupsPage.vue')
        },
        children: [
            {
                path: 'on-map',
                name: consts.routerNames.units_groups.map,
                meta: {
                    mainRouteName: consts.routerNames.units_groups.main,
                    pageClass: [pageClass, pageClass+'__map'].join(' '),
                    showMap: true,
                },
                props: {
                    default: (route) => {
                        return {
                            groupId: route?.params?.groupId
                        }
                    }
                },
                components: {
                    default: () => import("@/components/units-groups/UnitsGroupOnMap.vue"),
                }
            },
            {
                path: 'edit',
                name: consts.routerNames.units_groups.edit,
                meta: {
                    mainRouteName: consts.routerNames.units_groups.main,
                    pageClass: [pageClass, pageClass+'__edit'].join(' '),
                    showMap: false,
                },
                props: {
                    default: (route) => {
                        return {
                            groupId: route?.params?.groupId
                        }
                    }
                },
                components: {
                    default: () => import("@/components/units-groups/UnitsGroupEdit.vue"),
                }
            },
        ]
    },
];

export default routes;