// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {
    geozone: (route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.geoitems.main) {
            if(this.$isDevelopment) console.warn('geozone', route);
            return false
        }
        router.push({
            name: consts.routerNames.geoitems.main,
            params: {},
            query: {...route.query},
        })
    },
    geozoneId: (route, router, geozoneId) => {
        if(route.meta?.mainRouteName !== consts.routerNames.geoitems.main) {
            if(this.$isDevelopment) console.warn('geozoneId', route);
            return false
        }
        router.push({
            name: consts.routerNames.geoitems.main,
            params: {
                geozoneId,
            },
            query: {...route.query},
        })
    },
    geozoneFilter: (route, router, filter) => {
        if(route.meta?.mainRouteName !== consts.routerNames.geoitems.main) {
            if(this.$isDevelopment) console.warn('geozoneFilter', route);
            return false
        }
        let geozoneId = route.params?.geozoneId
        router.push({
            name: consts.routerNames.geoitems.main,
            params: {
                geozoneId,
            },
            query: {
                name: filter.name || undefined
            },
        })
    },
    geozoneEdit: (route, router, geozoneId) => {
        if(route.meta?.mainRouteName !== consts.routerNames.geoitems.main) {
            if(this.$isDevelopment) console.warn('geozoneEdit', route);
            return false
        }
        router.push({
            name: consts.routerNames.geoitems.edit.main,
            params: {
                geozoneId,
            },
            query: {...route.query},
        })
    },
    returnToParent:(route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.geoitems.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.geoitems.main+' returnToParent', route);
            return false;
        }
        let name = route.meta?.parentRouteName || consts.routerNames.geoitems.main;
        router.push({
            name: name,
            params: {
                ...route.params,
            },
            query: {...route.query}
        })
    },
    liteTemplate: (route, router, lite, noList=false) => {
        if(route.meta?.mainRouteName !== consts.routerNames.geoitems.main) {
            if(this.$isDevelopment) console.warn('liteTemplate', route, lite);
            return false;
        }
        // let routeName = route.name.split('.')
        // let lastRoute = routeName[routeName.length - 1]
        // routeName = lastRoute === 'lite' ? routeName.filter(n => n !== lastRoute) : routeName
        // if(lite){
        //     routeName.push('lite')
        // }
        router.push({
            name: route.name,
            params: {
                ...route.params,
                noList: noList ? 'no-list' : '',
            },
            query: {...route.query}
        })
    },
}

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'geoitems/:noList(no-list)?/:geozoneId(\\d+)?',
        name: consts.routerNames.geoitems.main,
        meta: {
            list: 'GeoitemsList',
            mainRouteName: consts.routerNames.geoitems.main,
            showMap: true,
            isLimited: false,
            layers: {
                ...consts.defaultSettingsLayers,
                units: {visible: false, showInList: true, showLayer: true},
                geoitems: {visible: true, showInList: true, showLayer: true},
            },
            setRoute: defaultSetRoute,
        },
        children: [
            {
                path: 'lite',
                name: consts.routerNames.geoitems.main+'.lite',
                meta:{
                    mainRouteName: consts.routerNames.geoitems.main,
                    parentRouteName: consts.routerNames.geoitems.main,
                    pageClass: 'geozone-lite',
                },
            },
            {
                path: 'edit',
                name: consts.routerNames.geoitems.edit.main,
                meta: {
                    mainRouteName: consts.routerNames.geoitems.main,
                    parentRouteName: consts.routerNames.geoitems.main,
                    sectionInfoByRoute: true,
                    layers: {
                        ...consts.defaultSettingsLayers,
                        units: {visible: false, showInList: true, showLayer: true},
                        geoitems: {visible: false, showInList: true, showLayer: true},
                        geoitem_edit: {visible: true, showInList: false, showLayer: true},
                    }
                },
                props: {
                    sectionInfoByRoute: (route) => ({
                        geozoneId: +route.params.geozoneId,
                        time: Date.now(),
                    })
                },
                components: {
                    sectionInfoByRoute: () => import('@/components/geoitems/sectionInfo/GeozoneEdit.vue'),
                }
            },
        ],
        component:() => import('@/views/prometheus/portal/GeoitemsPage.vue'),

    },
];

export default routes