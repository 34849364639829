const consts = {
    default_route: '/units',
    fetchAppProps: {
        dispatches: [
            {name: 'fetchUserSettings', params: {}},
            {name: 'fetchUnitsIcons', params: {}},
            {name: 'fetchUnitsHwTypesAll', params: {}},
            {name: 'fetchUnitsTypes', params: {}},

            {name: 'fetchAppUser', params: {}},

            {name: 'fetchAppObjects', params: [
                {name: 'fetchUnitsAll', params: {}},
                {name: 'fetchUnitsLmsgsAll', params: {withAddress: 1}},
                {name: 'fetchVideoStatusesAll', params: {}},

                {name: 'fetchUnitsGroupsAll', params: {}},

                {name: 'fetchUsersAll', params: {}},
                //{name: 'fetchUsersCustomizesAll', params: {}},

                {name: 'fetchGeopointsAll', params: {}},
                {name: 'fetchGeozonesAll', params: {}},

                {name: 'fetchGeopointsGroupsAll', params: {}},
                {name: 'fetchGeozonesGroupsAll', params: {}},
                {name: 'fetchDriversAll', params: {}},
                {name: 'fetchAppointmentsAll', params: {}},

                {name: 'fetchInsuranceReportsAll', params: {}},

                // {name: 'fetchNotificationsAll', params: {}},
            ]},

            //{name: 'fetchNotificationsAll', params: {}},
            {name: 'fetchEvents4Date', params: {date: 'today', params: {withAddress: 1}}},
            {name: 'fetchVideoEvents4Date', params: {date: 'today', params: {withAddress: 1}}},
            {name: 'fetchNotificationsEvents4Date', params: {date: 'today', params: {withAddress: 1}}},
        ],
    },
    reloadAppProps: {
        dispatches: [
            {name: 'reloadUnitsAll', params: {}},
            {name: 'reloadUnitsLmsgsAll', params: {withAddress: 1}},
            {name: 'fetchVideoStatusesAll', params: {}},
            //{name: 'reloadDriversAll', params: {}},

            // {name: 'reloadGeopointsAll', params: {}},
            // {name: 'reloadGeozonesAll', params: {}},

            // {name: 'reloadNotificationsAll', params: {}},

            // {name: 'reloadEvents', params: { params: {withAddress: 1}}},
            // {name: 'reloadVideoEvents', params: { params: {withAddress: 1}}},
            // {name: 'reloadNotificationsEvents', params: { params: {withAddress: 1}}},
        ],
        commits: [
            {name: 'clearTrips', params: {}},
            {name: 'clearParkings', params: {}},
            {name: 'clearTracks', params: {}},

            {name: 'clearEvents', params: {}},
            {name: 'clearVideoEvents', params: {}},
            {name: 'clearNotificationsEvents', params: {}},
            {name: 'clearStops', params: {}},

            {name: 'clearVideoRequests', params: {}},
            {name: 'clearVideoRecords', params: {}},
        ],
    },
    fetchByTimer: {
        dispatches: [
            //{name: 'fetchUserSettingsChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsIconsChanged', eachT: 30, params: {}},
            {name: 'fetchUnitsHwTypesChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsTypesChanged', eachT: 30, params: {}},

            //{name: 'fetchAppUserChanged', eachT: 30, params: {}},

            {name: 'fetchUnitsChanged', eachT: 30, params: {}},
            {name: 'fetchUnitsLmsgsChanged', eachT: 30, params: {withAddress: 1}},
            {name: 'fetchVideoStatusesAll', eachT: 30, params: {}},

            {name: 'fetchUnitsGroupsChanged', eachT: 30, params: {}},

            {name: 'fetchUsersChanged', eachT: 30, params: {}},
            //{name: 'fetchUsersCustomizesChanged', eachT: 30, params: {}},

            {name: 'fetchGeopointsChanged', eachT: 30, params: {}},
            {name: 'fetchGeozonesChanged', eachT: 30, params: {}},

            {name: 'fetchGeopointsGroupsChanged', eachT: 30, params: {}},
            {name: 'fetchGeozonesGroupsChanged', eachT: 30, params: {}},

            // {name: 'fetchNotificationsChanged', eachT: 30, params: {}},

            {name: 'fetchLastEvents', eachT: 30, params: {last: '15 minutes', params: {withAddress: 1}}},
            {name: 'fetchLastVideoEvents', eachT: 30, params: {last: '15 minutes', params: {withAddress: 1}}},
            {name: 'fetchLastNotificationsEvents', eachT: 30, params: {last: '15 minutes', params: {withAddress: 1}}},

            {name: 'fetchAppointmentsChanged', eachT: 30, params: {}},
            {name: 'fetchDriversChanged', eachT: 30, params: {}},
            {name: 'fetchInsuranceReportsChanged', eachT: 30, params: {}},

            {name: 'removeIndexToday', eachT: 15, params: {}},
        ],
    },
    api: {
        user: {
            //fields: [],
            expand: ['units_of_measure', 'settings', 'notifications_settings', 'is_admin', 'is_super_admin', 'parents', 'dealer', 'dealer_apikeys', 'dealers_apikeys', 'hide_menu', 'dealers_hide_menu', 'head__logo', 'dealer_login_logo', 'map_apikeys'],
        },

        unit_ext_services: {
            fields: ['id',],//!!!no changed__time
            expand: ['ext-services'],
        },
        units_lite: {
            fields: ['id', 'hw_type', 'name','parent__id', 'video'],//,'icon','icon_color','icon_halo'
            expand: [], //'access_right','user_creator', counters
        },
        units: {
            //fields: ['id','hw_type','hw_id','name','type','unit_icon','icon','max_speed','phone'],
            expand: ['user_creator', 'access_right', 'drivers'], //,counters, 'video'
        },

        drivers_lite: {
            fields: ['id','name'],
            expand: [], //,counters, 'video'
        },
        drivers: {
            //fields: ['id','name'],
            expand: [], //,counters, 'video'
        },

        appointments_lite: {
            fields: ['id', 'driver_id', 'unit_id', 'object_id', 'time_appointment', 'time_removed', 'employee_type'],
            expand: [], //,counters, 'video'
        },
        appointments: {
            //fields: ['id', 'id_units', 'id_driver'],
            expand: [], //,counters, 'video'
        },

        units_groups_lite: {
            fields: ['id', 'name', 'icon'],
            expand: [],
        },
        units_groups: {
            // fields: ['id',],
            expand: ['access_right'],
        },

        unitslmsgs_lite: {
            fields: ['object_id', 'unit_id', 'id_unit', 'time', 'latlng', 'lbs_location', 'timeAgo'],
            //expand: [],
        },
        unitslmsgs: {
            // fields: ['object_id'],
            expand: ['access_right'],
        },

        users_lite: {
            fields: ['id','creator','login','email','phone','status','status_text'],
            //expand: [],
        },
        users: {
            fields: ['id','creator','login','email','phone','legal_name','time_zone','dst','status','status_text'],
            expand: ['user_creator', 'is_admin', 'drivers'],
        },

        geopoints_lite: {
            fields: ['id', 'name', 'lat', 'lon', 'icon'],
            // expand: [],
        },
        geopoints: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'],
        },

        geopoints_groups_lite: {
            fields: ['id', 'name', 'geopoints'],
            // expand: [],
        },
        geopoints_groups: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'],
        },

        geozones_lite: {
            fields: ['id', 'name', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            // expand: [],
        },
        geozones: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right','points'],
        },

        geozones_groups_lite: {
            fields: ['id', 'name', 'geozones'],
            // expand: [],
        },
        geozones_groups: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'],
        },

        notifications: {
            //fields: [],
            expand: ['units', 'units-groups', 'geozones', 'geopoints'],
        },
        notifications_lite: {
            //fields: [],
            expand: ['units', 'units-groups', 'geozones', 'geopoints'],
        },

        insurance_reports: {
            //fields: [],
            expand: ['form_items', 'form_values'],
        },
        insurance_reports_lite: {
            //fields: [],
            //expand: [],
        },

        events: {
            //fields: [],
            expand: [],
        },
        events_lite: {
            //fields: [],
            expand: [],
        },
        video_events: {
            //fields: [],
            expand: [],
        },
        video_events_lite: {
            //fields: [],
            expand: [],
        },
        video_requests: {
            //fields: [],
            expand: ['image'],
        },
        video_requests_lite: {
            //fields: [],
            expand: [],
        },
        video_records: {
            //fields: [],
            expand: [],
        },
        video_records_lite: {
            //fields: [],
            expand: [],
        },

        maintenance_vendors: ['access_right']

    },
    components: {
        UnitEdit: {
            defaults: {
                options: {
                    components: [
                        'UnitEdit_Options_prometheus',
                        'UnitEdit_Icon_prometheus',
                        'UnitEdit_AddInfo_prometheus',
                        'UnitEdit_Sensors_prometheus',
                    ]
                }
            },
            xCargoUnit: {
                options: {
                    components: [
                        'UnitEdit_Options_intuit',
                        'UnitEdit_Delivery',
                        'UnitEdit_xCargoSensors',
                    ]
                }
            }
        }
    },
}

export default consts