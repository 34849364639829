// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {}

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'locator/:unitId(\\d+)?',
        name: consts.routerNames.locator.main,
        meta: {
            mainRouteName: consts.routerNames.locator.main,
            showAsideMenu: false,
            isGuest: true,
            weatherInMap: true,
            pageClass: 'unit-dashboard locator-page',
            showMap: true,
            unitTracking: true,
            layers: {
                ...consts.defaultSettingsLayers,
                units: {visible: true, showInList: true, showLayer: true},
            },
            setRoute: defaultSetRoute,
        },
        props: {
            default: (route) => ({unitId: route.params.unitId})
        },
        components: {
            default: () => import('@/views/intuit/portal/LocatorPage.vue')
        }
    },
];

export default routes;